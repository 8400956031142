.App {
  text-align: center;
}

@import url('/src/utils/cssUtilities.css');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Screen Count Component */
#linktargetframe {
  border: 1px solid #205081;
  -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

#linktargetframe #linktargetframe_title {
  padding: 6px 4px;
  margin: 0;
  font-size: 115%;
  background-color: #d2d2d2;
  border-bottom: 1px solid #ebebeb;
}

#linktargetframe #linktargetframe_body {
  background-color: white;
  padding: 8px 4px;
}

#linktargetframe #linktargetframe_title button {
  margin-top: -5px;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.close {
  font-weight: bold;
  font-size: 25px;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
button {
  min-height: 26.21px;
}

#linktargetframe #linktargetframe_title {
  padding: 6px 4px;
  margin: 0;
  font-size: 115%;
  background-color: #d2d2d2;
  border-bottom: 1px solid #ebebeb;
}
#linktargetframe .item {
  border: 1px solid rgb(32, 80, 129);
  background-color: #b3d1e6;
  padding: 1px 3px;
  margin: 0 4px;
  min-width: 25px;
  display: inline-block;
  text-align: center;
}

div#tilebanner {
  display: none;
  color: #205081;
  background-color: #b3d1e6;
  font-size: 200%;
  position: absolute;
  z-index: 1060;
  padding: 3px 12px;
  border: 6px solid rgb(32, 80, 129);
}
.dropdown-menu.pull-right {
  width: inherit;
}
.open>.dropdown-menu {
  display: block;
  border: 1px solid #aaa;
}
.dropdown-menu.pull-right {
  right: 190px;
  left: auto;
  top: 39px;
}
.dropdown-menu {
  font-size: 12px;
}
.dropdown-menu {
  position: absolute;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  margin: 0px;
  z-index: 4;
  background-color: #ffffff;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 20%);
}
.pull-right {
  float: right!important;
}
.dropdown-menu {
  min-width: 160px;
  text-align: left;
  background-clip: padding-box;
}
.dropdown-menu li > span {
  padding: 2px 5px 2px 5px !important;
  color: #333;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 25px;
  white-space: nowrap;
}
.dropdown-menu li > span:hover{
  background-color: #428bca;
  color: #fff;
}

#alertcontainer {
  max-height: 100px;
  overflow: hidden;
}

.alertstyle-info {
  border-color: #45abcd;
  background-color: #e2f0f7;
}
.alertstyle-lg {
  padding: 10px;
  font-size: 120%;
}
.alertstyle-leftborder {
  padding: 10px;
  border-left: 15px solid;
  margin-bottom: 10px;
}
