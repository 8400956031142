.headerContainer{
    display: flex;
    /* width: 50%; */
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    flex:1;
}
.controlers{
    display: flex;
    /* width: 34%; */
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}
.controlers button {
    margin-left: 12px;
}
.records{
    display: flex;
    /* width: 40%; */
    gap: 40px;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}
.controlersRecord{
    display: flex;
    width: 45%;
    justify-content: space-evenly;
    align-items: center;
}
.ButtonControl{
   border-radius: 0%;

}
.headerMenu{
   
   
}
.field{
    
    margin-top: 10px;
    justify-content: flex-end;
    width: 100%;
}
.fieldContainer{
    margin-top: 5px;
}
.batchMode{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 10%;
}