html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

#root {
  overflow: auto;
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: normal;
  src: url("./assets//fonts//Orbitron-Regular.ttf") format("truetype");
}