.sub-nav-container{
    height: 40px;
    text-align: start;
    margin: 1px 0px;
    background-color: #eeeeee;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-left: 5px;
   
}
.page-title {
    padding: 0 5px 0 5px;
    font-size: 22px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0 0 5px 0;
    font-weight: 100;
}