table#transportdetailinfo td { white-space: unset; }
table#transportdetailinfo thead th { text-align: center; }
.volumeloaded { background-color: #627FD9; margin: 1px 1px 0 1px; opacity: 0.4; }
.volumeloading { background-color: #4CCE3A; margin: 1px 1px 0 1px; opacity: 0.4; }
.volumeloadedalarm { background-color: red; margin: 1px 1px 0 1px; opacity: 0.4; }
.volumeloadingalarm { background-color: red; margin: 1px 1px 0 1px; opacity: 0.4; }

table.compartment { table-layout: fixed; height: 100%; width: 100%; }
table.compartment td { border: 1px solid rgba(136, 136, 136, 0.3); }

.facdetailditto { width: 1%; }