.inputSearch{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-width: 1 #000 solid;
    height: 70px;
   
}
.searchIcon{
    height: 41px;
    width: 41px;
    border-bottom-right-radius:5px ;
    border-top-right-radius:5px ;
    background-color: #e5e5e5;
    color: #999999;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: -40px;
    border:0.5px #cdcdcd;
    z-index: 3;
}
.SmartNavinputSearch{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-width: 1 #000 solid;
    background-color: #cdcdcd;
    height: 70px;
   
}