

	.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover,
	.btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover,
	.open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus,
	.open>.dropdown-toggle.btn-default:hover
	{
		color: #fff;
		background-color: #3A3A40; 
		border-color: #494F57; 
	}

	.rcu
	{
		display: inline-block;
		font-size: calc(4px + 0.45vw);
		margin: 1px;
	}

	.rcutitle { padding-left: 5px; font-size: 18px; border: 1px solid #7A8299; }

	.rcusubtitle { font-size: 12px; }

	.rcuscreen
	{
		color: black;
		text-align: center;
		background-color: #C5FFFF;
		border: 1px solid #7A8299;
		font-family: DroidSans, monospace;
		font-size: calc(4px + 0.45vw);
		font-weight: 100;
		padding: 2px;
		margin: 0;
		cursor: pointer;
	}

	.rcuscreen.error { color: black; background-color: #FFD9D9; }

	.keypadgroup
	{
		position: absolute;
		border: 1px solid #555;
		background: #eee;
		top: 0;
		z-index: 1;
	}

	.rcukeygrp1
	{
		table-layout: fixed;
		border: 1px solid #eee;
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 5px;
	}

	.rcukeygrp2
	{
		table-layout: fixed;
		border: 1px solid #eee;
		width: 100%;
		border-collapse: collapse;
	}

	.rcukeygrp1 td
	{
		text-align: center;
		border: 1px solid #eee;
		padding: 0 2px;
	}

	.rcukeygrp2 td
	{
		text-align: center;
		border: 1px solid #eee;
		padding: 0 1px;
	}

	.rcukeygrp1 tr
	{
		height: 28px;
	}

	.rcukeygrp2 tr
	{
		height: 28px;
	}

	.dittokey
	{
		font-size: calc(4px + 0.45vw);
	}

	