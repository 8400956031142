
		div.tankcontainergroup { border-right: 2px solid #ddd;  }
		table.tankinfogroupcontainer { width: 100%; }
		table.tankinfogroupcontainer > tbody > tr > td { text-align: center; }
		table.tankinfogroupcontainer > tbody > tr > td:nth-child(2) { width: 249px; transform: translateY(-10%); }

		div.interpolatetankimagecontainer img#tankimageinterpolate { width: 25vw; min-width: 280px; }

		h4.fractionsheader { padding-left: 10%; width: 90%; }
		div.fractionscontainer { display: inline-table; }
		th.levelother.volume_header { min-width: 90px;  border: 1px solid #ddd; }

		table.tankstrapheading { width: 100%; }
		table.tankstrapheading h2 { margin-top: 0; }
		table.tankstrapheading td h2 { text-align: center; }

		table.tankstrapfractions { width: 100%; border-collapse: collapse; margin-bottom: 1px; margin-right: 1px; }
		table.tankstrapfractions > tbody > tr > td { border: 1px solid #ddd; height: 22px; }
		table.tankstrapfractions > tbody > tr > th { border: 1px solid #ddd; border-bottom: 2px solid #aaa; font-weight: bold; height: 22px; text-align: center; }
		
		table.tankstrapfractions input { border: 1px solid transparent; padding: 0; text-align: center; }

		table.tankstrapoptions { width: 100%; border-collapse: collapse; margin-bottom: 1px; margin-right: 1px; }
		table.tankstrapoptions > tbody > tr > td { border: 0px solid #ddd; height: 22px; }
		table.tankstrapoptions > tbody > tr > th { border: 0px solid #ddd; border-bottom: 2px solid #aaa; font-weight: bold; height: 22px; text-align: center; }

		table.tankstraplevel { border-collapse: collapse; margin-bottom: 1px; margin-right: 1px; }
		table.tankstraplevel > tbody > tr > td { border: 1px solid #ddd; height: 22px; }
		table.tankstraplevel > tbody > tr > th { border: 1px solid #ddd; border-bottom: 2px solid #aaa; font-weight: bold; height: 22px; }

		table.tankstraplevel > tbody > tr > th:nth-child(1) { width: 34px; text-align: center; }
		table.tankstraplevel > tbody > tr > th:nth-child(2) { width: 90px; text-align: center; }

		table.tankstraplevel td.levelone { text-align: left; padding: 0 5px; font-weight: bold; }
		table.tankstraplevel td.levelother { text-align: right; padding: 0 5px; }
		table.tankstraplevel td.levelvalue { text-align: right; text-align: center; padding: 0; }
		table.tankstraplevel td.noborder { border: 0; }

		div.fractionscontainer > table.tankstraplevel > tbody > tr > th:nth-child(1) { width: 94px; text-align: center; }

		table.tankstraplevel input { border: 1px solid transparent; padding: 0; text-align: center; }
		.strapheightactive, .strapheightactive input { background-color: #CCD9FF !important; }

		.unsaved, .unsaved input { background-color: #f4fadb !important; }

		table.interpolation_table { width: 100%; }

		.ui-slider-range { background-color: #f4fadb; }

		.interpolate { cursor: pointer; }
		.interpolate.start { background-color: #f4fadb; }
		.interpolate.end { background-color: #f4fadb; }
		.interpolate.inbetween { background-color: #f4fadb; }
		
		.tankcontainer
		{
			min-height: 300px;
			display: flex;
			flex-flow: column wrap;
			align-content: flex-start;
			overflow-x: scroll !important;
			height: calc(100vh - 300px);
		}

		.gaugeheightcontainer
		{
			min-height: 100%;
			min-width: 100%;
			overflow-y: auto;
		}
		.form-control {
			height: auto;
			padding: 4px 4px;
			font-weight: 400 !important;
			font-size: 12px;
			line-height: 1.42857143;
			color: #333333;
    		background-color: #ffffff;
			box-shadow: none;
			transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
			display: block;
			width: 100%;
		}
		