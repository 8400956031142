.footersize {
    font-family: 'Courier New';
    font-size: 12px;
    min-height: 14px;
    height: 20px;
    table-layout: fixed;
    width: 100%;
    z-index: 4;
}
.copyright {
    text-align: left;
    margin: 3px 0px 4px;
    padding-left: 15px;
}
.targetname {
    text-align: center;
    margin: 3px 0px 4px;
}
.targetinfo {
    float: right;
    margin: 5px 0px 4px;
    padding-right: 15px;
}
.navbar-bifooter {
    background-color: #205081;
    border-color: #777777;
    color: #fff;
    position: inherit;
    bottom: 0;
}
.navbar-fixed-bottom {
    border-width: 1px 0 0; 
}
@media only screen and (max-width: 1024px){
    .targetname{
        display: none;
    }
    .copyright{
        width: 100%;
    }
    
}