.accordion {
  list-style: none;
}

.accordion_item {
  border-top: 1px solid #9f9f9f;
}

.button {
  font-size: 16px;
  background-color: #fff;
  color: black;
  text-align: left;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
}

.control {
  font-size: 20px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
  margin-right: 10px;
}
.control:hover {
  background-color: #ddd;;
}

.answer {
  background-color: #fff;
  padding: 10px;
}

/* activate toggle */
.accordion_item.active .button {
  background-color: #d8f0ff;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
/* .answer_wrapper.open {
  height: auto;
} */

