.tableRowHead th{
    font-weight: 100;
    color: #039;
    border-bottom: 1px solid #6678b1;
    white-space: nowrap;
    font-size: 12px;
    text-align: left;
}
.orderMaintenance{
    border-spacing: 0;
    border-collapse: collapse;
    width: 1800px;
    position: relative;
    margin-top:25px;
}
.css-1p5i4h2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    margin-top: 0px !important;
}
.orderQty .css-12ctro4-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
}
.detail-row{
    padding: 0 5px 2px 5px;
    border: 1px solid #D8D8D8;
    margin-bottom: 15px;
}
.detail-row:hover{
    background-color: #F4F8FF;
}
.detail-row td{
    padding: 4px 2px;
}
.required-alert{
    border-left: 15px solid;
    margin: 10px 0px;
    padding: 10px;
    border-color: #F53500;
    background-color: #F2DEDE;
}
.required-alert .icon{
    display: flex;
    justify-content: space-between;
}
.icon-style{
    color: #C1C1C1;
}
.icon-style:hover{
    color: #F53500;
}
.list-item{
    font-size: 10px !important;
}
.required-alert p{
    margin: 2px !important;
}
.uom-select{
    min-width: 150px !important;
    border-left: 5px solid black !important;
}
.custom-uom{
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
}
.button-submit{
    display: flex;
    justify-content: end;
}
.css-1k51tf5-MuiTooltip-tooltip{
    background-color: rgba(255, 0, 0, 0.4) !important;
    border: 1px solid #e96666;
}
.css-kudwh-MuiTooltip-arrow{
    color:#e96666 !important;
}