.dropzone{
    border: 1px dashed #aaa;
    padding: 100px 0px;
    text-align: center;
}
.chose-file-section{
    display: flex;
}
.file-btn{
background-color:#337ab7;
padding: 5px 10px;
width: 14%;
}
.select_files{
border: 1px solid #e5e5e5;
width: 100%;
}
.chose-file-section{
    margin-top: 5px;
}
.file-card{
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    padding: 6px;
    float: left;
    text-align: center;
}
.remove{
    background-color: #d9534f;
}
.uploadall{
    background-color: #5bc0de;
}